@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --text: 0 0% 0%;
    --muted: 240 1% 73%;
    --background: 0 0% 100%;
    --primary: 165 93% 43%;
    --secondary: 225 82% 54%;
    --tertiary: 267 53% 48%;
    --gray: 240 4% 26%;
    --card: 0 0% 100%;
  }

  .dark {
    --text: 0 0% 100%;
    --muted: 240 1% 73%;
    --background: 0 0% 4%;
    --primary: 165 93% 43%;
    --secondary: 225 82% 54%;
    --tertiary: 267 53% 48%;
    --gray: 240 4% 26%;
    --card: 0 0% 0%;
  }
}
