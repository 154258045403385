@import './theme.css';

html,
body,
#root {
  @apply flex h-full w-full grow flex-col;
}

body {
  @apply m-0 bg-background bg-[url('../assets/images/light-bg.png')] bg-contain bg-center bg-repeat-x !font-articulat text-text;
}

.dark {
  body {
    @apply bg-[url('../assets/images/dark-bg.png')];
  }
}

*,
::before,
::after {
  @apply box-border border-0 border-solid border-current;
}

.ant-btn-default {
  @apply border-0 !bg-gray !text-white !shadow-none;
}

.ant-btn-default:hover {
  @apply !opacity-75;
}

.ant-btn-primary {
  @apply border-0 !bg-action-btn !text-white !shadow-none;
}

.ant-btn-primary:hover {
  @apply !opacity-75;
}

.ant-modal-content {
  @apply !bg-card;
}

.ant-modal-confirm-body {
  @apply w-full;
}

.ant-modal-confirm-content {
  @apply w-full;
}

.ant-select-selector {
  @apply !rounded-[30px] !border-[1px] !border-text/20 !bg-card;
}

.ant-select-arrow {
  @apply !text-text;
}

.ant-select-item-option {
  @apply !bg-card hover:!bg-text/20;
}

.ant-dropdown-menu-item {
  @apply hover:!bg-text/20;
}

.ant-dropdown {
  @apply !top-[60px];
}

a {
  @apply underline duration-200;
}

a:hover {
  @apply !text-green underline;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../assets/fonts/articulatcf-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../assets/fonts/articulatcf-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../assets/fonts/articulatcf-demibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../assets/fonts/articulatcf-bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
